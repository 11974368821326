import { useEffect, useState, MutableRefObject } from 'react';

export const OFFSET_COL_SIZE = 40;
export const OFFSET_LEFT_SCROLL = 20;

export const useTabNavigation = (
  columnsRef: MutableRefObject<HTMLElement>,
  activeTab = 0,
) => {
  const [selectedTab, setSelectedTab] = useState(activeTab);
  let isScrolling: ReturnType<typeof setTimeout>;
  let tabClicked = false;

  useEffect(() => {
    if (!('scrollBehavior' in document.documentElement.style)) {
      import('smoothscroll-polyfill').then((smoothscroll) =>
        smoothscroll.polyfill(),
      );
    }

    if (columnsRef.current) {
      columnsRef.current.addEventListener('scroll', handleScroll, false);

      if (activeTab > 0) {
        handleTabClick(activeTab);
      }
    }

    return () => {
      columnsRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    clearTimeout(isScrolling);
    isScrolling = setTimeout(() => {
      isScrolling = null;
      tabClicked = false;
    }, 100);

    if (!tabClicked) {
      setSelectedTab(Math.round(columnsRef.current.scrollLeft / colSize()));
    }
  };

  const handleTabClick = (tabIndex: number) => {
    const leftScroll =
      tabIndex > 0
        ? colSize() * tabIndex +
          OFFSET_LEFT_SCROLL -
          columnsRef.current.scrollLeft
        : columnsRef.current.scrollLeft * -1;
    tabClicked = true;
    if (columnsRef.current.scrollBy) {
      columnsRef.current.scrollBy({
        top: 0,
        left: leftScroll,
        behavior: 'smooth',
      });
    }
  };

  const colSize = (): number =>
    columnsRef.current.scrollWidth / columnsRef.current.childElementCount -
    OFFSET_COL_SIZE;

  return {
    selectedTab,
    handleTabClick,
  };
};
