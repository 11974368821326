import cx from 'classnames';
import {
  LoadingSpinner,
  PriceText,
  Spacer,
  Text,
  Tooltip,
  Footnote,
  Flag,
  ListIcon,
} from 'dss-ui-library';
import { TextLink } from '@ncs-frontend-monorepo/content-library';
import { getImageProps } from '@ncs-frontend-monorepo/utils';
import dynamic from 'next/dynamic';
import NextImage from 'next/image';
import React from 'react';
import { ProductInfoWithPrice } from '../../interfaces';
import { useModalContext } from '../ModalContext';
import { getPromotionInfo } from '@ncs-frontend-monorepo/order';
import styles from './ProductItem.module.scss';

const ProductModal = dynamic(
  () =>
    import('../Modals/ProductModal').then(({ ProductModal }) => ProductModal),
  {
    ssr: false,
    loading: () => <LoadingSpinner theme="blue" />,
  },
);

interface ProductItemProps extends ProductInfoWithPrice {
  withFlag: boolean;
}

export const ProductItem: React.FC<ProductItemProps> = ({
  id,
  image,
  icon,
  name,
  shortDesc,
  tooltip,
  priceInfo,
  flag,
  footnoteFlag,
  footnote,
  description,
  withFlag,
  category,
  type,
}) => {
  const { handleModal } = useModalContext();

  const handleClick = () => {
    handleModal({
      content: (
        <ProductModal
          image={image}
          description={description}
          flag={flag}
          name={name}
          icon={icon}
          shortDescription={
            type === 'device' &&
            `${category === 'premium' ? 'Premium' : 'Basic'} WLAN-Router`
          }
        />
      ),
      e2e: `product-modal`,
    });
  };

  const priceInfoText = priceInfo?.runtime ? 'mtl.' : 'einmalig';
  const promotionInfo = getPromotionInfo(priceInfo);
  return (
    <div data-e2e={`product-comparison-${id}`}>
      <div
        className={cx(styles.productVisual, { [styles.withFlag]: withFlag })}
      >
        {image && (
          <div className={styles.productImage}>
            <NextImage
              {...getImageProps(image)}
              sizes="100vw"
              style={{
                width: '100%',
                height: 'auto',
              }}
            />
          </div>
        )}
        {icon && (
          <div>
            <ListIcon size={80} iconType={icon} iconColor="blue" />
          </div>
        )}
        {flag && (
          <Flag
            className={styles.flag}
            color="red"
            footnote={
              footnoteFlag && (
                <Footnote number={footnoteFlag.number} color="white" />
              )
            }
          >
            {flag}
          </Flag>
        )}
      </div>
      <Spacer y={2} block>
        <Text appearance="t2" element="h3">
          {name}
        </Text>
      </Spacer>
      <Text appearance="t4">
        {shortDesc}
        {tooltip && (
          <div className={styles.productItemTooltip}>
            <Tooltip>
              <Text appearance="t5">{tooltip}</Text>
            </Tooltip>
          </div>
        )}
      </Text>
      <Spacer t={2} block />
      {priceInfo && (
        <>
          <PriceText
            footnote={footnote && <Footnote number={footnote.number} />}
            info={priceInfoText}
            price={
              priceInfo.specialOfferPrice !== null
                ? priceInfo.specialOfferPrice
                : priceInfo.price
            }
          />
          {promotionInfo && (
            <Spacer t={1}>
              <Text appearance="t6" element="h3">
                {promotionInfo}
              </Text>
            </Spacer>
          )}
        </>
      )}
      <Spacer b={3} block />
      <TextLink url={null} onClick={handleClick}>
        Mehr erfahren
      </TextLink>
    </div>
  );
};
