import React, { useRef } from 'react';
import { ContentProductComparison } from '../../interfaces';
import { Grid, Spacer, Text } from 'dss-ui-library';
import styles from './ProductComparison.module.scss';
import cx from 'classnames';
import { ProductItem } from './ProductItem';
import { useTabNavigation } from '../../hooks/useTabNavigation';

export type ProductComparisonProps = ContentProductComparison['props'];

const ProductComparison: React.FC<ProductComparisonProps> = ({
  headline,
  text,
  backgroundColor,
  items = [],
  headlineElement = 'h2',
}) => {
  const columnsRef = useRef(null);

  const { selectedTab, handleTabClick } = useTabNavigation(
    columnsRef,
    items.findIndex((item) => item.props.flag),
  );
  const withFlag = items.some((item) => item.props.flag !== '');

  return (
    <div
      className={cx(styles.productComparison, {
        [styles.greyBackground]: backgroundColor !== 'white',
      })}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column>
            {headline && (
              <Spacer b={2} block>
                <Text appearance="t1_2" color="blue" element={headlineElement}>
                  {headline}
                </Text>
              </Spacer>
            )}
            {text && (
              <Spacer b={4} block>
                <Text appearance="t4">{text}</Text>
              </Spacer>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid fullWidthOnMobile>
        <Grid.Row className={styles.row} ref={columnsRef}>
          {items.map((productItem, ix) => (
            <Grid.Column
              key={`${productItem.id}-${ix}`}
              m={6}
              className={cx(styles.column, {
                [styles.gutterLeft]: ix === 0,
                [styles.gutterRight]: ix === items.length - 1,
              })}
            >
              <ProductItem withFlag={withFlag} {...productItem?.props} />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
      <Grid.Row>
        <Grid.Column hideS>
          <div className={styles.productNav}>
            {items.map((productItem, ix) => (
              <div
                className={cx(styles.productNavItem, {
                  [styles.active]: ix === selectedTab,
                })}
                key={`${ix}-${productItem.id}`}
                onClick={() => handleTabClick(ix)}
              />
            ))}
          </div>
        </Grid.Column>
      </Grid.Row>
    </div>
  );
};

export default ProductComparison;
